import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { LocalForage } from '../../../../utils/LocalForage';

import { UserID } from '../../../users/usersTypes';

import { FetchMaterialsSetByUserIdScopeType } from '../../materialsSetsTypes';

import {
  FetchMaterialsSetByUserIdError,
  FetchMaterialsSetByUserIdResponse,
  MaterialsSetByUserIdItemOptimisticUpdateType
} from './types';

import { useUpdateMaterialsSetCache } from './hooks/useUpdateMaterialsSetCache';

import { fetchMaterialsSetByUserId } from '../../actions/fetchMaterialsSetByUserId';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface MaterialsSetByUserIdOptions<MaterialsSetByUserIdItemType> {
  cacheKey: string;
  query: string;
  userId: UserID;
  scope?: FetchMaterialsSetByUserIdScopeType;
  options?: {
    cacheTime?: number;
    onSuccess?: (
      data: FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
    ) => void;
  };
}

const itemKey = 'materialsSetByUserId';

function useMaterialsSetByUserId<MaterialsSetByUserIdItemType>({
  cacheKey,
  query,
  userId,
  scope,
  options = {}
}: MaterialsSetByUserIdOptions<MaterialsSetByUserIdItemType>) {
  const currentUser = useCurrentUser();

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<
          FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
        >(cacheKey)
    );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, { userId, scope }];
  }, [cacheKey, userId, scope]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    FetchMaterialsSetByUserIdResponse<
      MaterialsSetByUserIdItemType &
        MaterialsSetByUserIdItemOptimisticUpdateType
    >,
    FetchMaterialsSetByUserIdError
  >(
    fullCacheKey,
    () =>
      fetchMaterialsSetByUserId({
        query,
        input: { userId, scope }
      }),
    {
      enabled: placeholderDataFetched,
      cacheTime: options.cacheTime,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        if (
          currentUser.get('id') === userId &&
          !data?.[itemKey]?.optimisticUpdate
        ) {
          LocalForage.setItem<
            FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
          >(cacheKey, data);
        }
      },
      placeholderData: () => {
        if (placeholderData && currentUser.get('id') === userId) {
          return placeholderData;
        }
      }
    }
  );

  const updateMaterialsSetCache = useUpdateMaterialsSetCache<
    MaterialsSetByUserIdItemType & MaterialsSetByUserIdItemOptimisticUpdateType
  >({
    fullCacheKey,
    itemKey
  });

  return {
    materialsSet: data?.[itemKey] || null,
    materialsSetError: parseRequestError(error),
    materialsSetFetched: isFetched,
    materialsSetLoading: isLoading,
    materialsSetIsPlaceholderData: isPlaceholderData,
    updateMaterialsSetCache
  };
}

export default useMaterialsSetByUserId;

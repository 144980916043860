import request from 'graphql-request';

import { ID } from '../../../../types';

import { FetchMaterialsSetByUserIdScopeType } from '../../materialsSetsTypes';

import { GRAPHQL_API_URI } from '../../../../config';
import { productionConsole } from '../../../../utils/productionConsole';
import { getQueryName } from '../../../../utils/getQueryName';
import { generateUuid } from '../../../../utils/generateUuid';

interface FetchMaterialsSetByUserIdProps {
  query: string;
  input: FetchMaterialsSetByUserIdInput;
}

export interface FetchMaterialsSetByUserIdInput {
  userId: ID;
  scope?: FetchMaterialsSetByUserIdScopeType;
}

export function fetchMaterialsSetByUserId({
  query,
  input
}: FetchMaterialsSetByUserIdProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchMaterialsSetByUserId',
    input,
    rawName,
    JSON.stringify(input)
  );

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    input,
    { requestId }
  );
}
